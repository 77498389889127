import React from 'react'


const PartnerValueChain = ({ data }) => {
    return (
        <div className="grid grid-cols-1 pb-2 text-center" >
            <table  className="table-auto w-4/5 mx-auto text-left whitespace-no-wrap max-w-screen-lg">
                <thead>
                    <tr className="text-sm leading-4 text-gray-500 uppercase tracking-wider">
                        {Object.keys(data[0]).map((key, index) => (
                            <th key={key} className={`px-3 py-3 ${index === 0 ? 'w-1/2' : ''}`}>{key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {data.map((row, index) => (
                        <tr key={index} className="text-sm leading-5 text-gray-900">
                            {Object.values(row).map((value, i) => (
                                <td key={i} className="px-2 py-2 border-t border-gray-200">{value}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PartnerValueChain