import axios from './index'

export function getEnroll() {
    return axios.get('/enroll')
}

export function updateEnroll(id, {
    frequentlyAskedQuestionsTitle = '',
    frequentlyAskedQuestionsDescription = '',
    enrollTitle = '',
    enrollDescription = '',
    enrollButton = '',
    backgroundImageUrl = ''
} = {}) {
    return axios.put(`/update-enrol/${id}`, {
        frequentlyAskedQuestionsTitle: frequentlyAskedQuestionsTitle,
        frequentlyAskedQuestionsDescription: frequentlyAskedQuestionsDescription,
        enrollTitle: enrollTitle,
        enrollDescription: enrollDescription,
        enrollButton: enrollButton,
        backgroundImageUrl: backgroundImageUrl
    })
}