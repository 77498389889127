import React, { useState } from 'react'
import ab01 from '../../assets/images/about/ab01.jpg';
import ab02 from '../../assets/images/about/ab02.jpg';
import Navbar from '../../components/Navbar'
import Works from '../../components/How-Work'
import PopularJobsSeven from '../../components/Popular-Jobs-Seven'
import QuesAnswer from '../../components/Question-Answer'
import Footer from '../../components/Footer'
import JobCounter from '../../components/Job-counter'
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import PopularCategories from '../../components/Popular-Categories';

import Image3 from '../../assets/images/banner/happy_banner3.jpg';
import Image4 from '../../assets/images/banner/happy_banner4.jpg';
import Image2 from '../../assets/images/banner/happy_banner2.jpg';

import { Carousel } from 'antd';


const contentStyle = {
    height: '1200px',
    color: '#fff',
    textAlign: 'center',
    background: '#364d79',
    objectFit: 'cover',
    objectPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

const customStyle = {
    color: '#5a6674',
}

export default function IndexSeven() {
    const [isOpen, setOpen] = useState(false)
    const items = [Image2, Image3, Image4];

    return (
        <>
            <Navbar navClass='justify-end' />
            <Carousel autoplay>
                {items.map((image, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <img style={{ ...contentStyle, position: 'absolute', zIndex: 1 }} src={image} alt="banner" />
                        <section className="relative flex md:h-screen h-auto items-center mt-4" style={{ zIndex: 2, }}>
                            <div className="container z-900" style={{ paddingTop: '30rem' }}>
                                <div className="grid grid-cols-1 mt-10 relative" style={{ backdropFilter: 'blur(3px)', width: '45rem', padding: '1rem 1rem 0 1rem', marginLeft: '10rem' }}>
                                    <h4 className="text-left lg:leading-normal leading-normal mb-5 font-bold" style={{ color: '#fff', fontSize: '2rem' }}>Harnessing Agricultural Productivity and Prosperity for Youth (HAPPY) Program</h4>
                                    <p className="text-left lg:leading-normal leading-normal text-base mb-5 text-white" style={{ backgroundColor: '#162339', padding: "0.5rem", }}>
                                        HAPPY is a an ambitious and transformative program aimed at unlocking work opportunities <br /> for youth and women-led businesses and reducing Ghana’s average annual food import bill by 10%
                                    </p>
                                    <a href='/aboutus' style={{ marginBottom: '5rem' }}>
                                        <button className="btn hover:bg-emerald-700 rounded-md" style={{ width: '10rem', color: '#fff', backgroundColor: '#162339' }}>Learn More</button>
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                ))}
            </Carousel>;

            <section className="relative md:py-24 py-16">
                <Works />
                {/* <BrowseByCategories /> */}
                <PopularCategories />
            </section>
            <section className="py-24 w-full table relative bg-[url('../../assets/images/hero/bg3.jpg')] bg-center bg-no-repeat bg-cover jarallax" data-jarallax data-speed="0.5">
                <div className="absolute inset-0 bg-slate-900/40"></div>
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div>
                            <div style={{ backdropFilter: 'blur(3px)', padding: '1rem' }}>
                                <h5 className="text-xl font-semibold text-white">Register for Participant</h5>
                                <p style={{ fontSize: '1rem', color: '#fff', marginTop: '0.5rem' }}>To enrol in the HAPPY program, simply click the button register to get started. We look forward to welcoming you to our network of partners and beneficiaries.</p>
                                {/* <ul className="list-none text-white mt-4">
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> Many desktop publishing packages</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> Contrary to popular belief</li>
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> It is a long established fact that a reader</li>
                            </ul> */}
                            </div>


                            <div className="mt-4">
                                <Link to="https://forms.office.com/r/T6X5ryXFk9" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full" target='_blank'>Register</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="relative md:py-24 py-16">
                <PopularJobsSeven />
                <div className="container md:mt-24 md:pb-24 pb-16 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="relative">
                                <div className="relative">
                                    <img src={ab01}
                                        className="lg:w-[400px] w-[280px] rounded-md shadow dark:shadow-gray-700" alt="" />
                                    <div className="absolute top-0 translate-y-2/4 end-0 text-center">
                                        <Link to="#" style={{ pointerEvents: 'none' }} onClick={() => setOpen(true)} data-type="youtube" data-id="yba7hPeTSjk"
                                            className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-emerald-600 dark:text-white">
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="absolute md:-end-5 end-0 -bottom-16">
                                    <img src={ab02}
                                        className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-md shadow dark:shadow-gray-700"
                                        alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                            <div className="lg:ms-5">
                                <h3 className="mb-6 md:text-[26px] text-2xl md:leading-normal leading-normal font-semibold">Unlock Limitless Opportunities: 326,000 Jobs Await in Our Partner Network</h3>

                                <p className="text-slate-300 max-w-xl">
                                    Step into a realm of abundant employment opportunities with our extensive partner network, boasting a staggering 326,000 available jobs. The program is not just about farming; it's a gateway to diverse careers spanning agriculture, technology, logistics, and more.
                                </p>

                                <ul className="list-none mt-4" style={customStyle}>
                                    <li style={{ marginLeft: '0.2rem' }} className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2">
                                    </i>Youth Entrepreneurship in Seed Systems Programme (YESSPRO)</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2">
                                    </i>Disability Inclusive Agriculture for Prosperity (DIAP)</li>
                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2">
                                    </i>Diversified Women Agriculture Occupations for Wealth (DiWaOW)</li>
                                </ul>

                                <div className="mt-6">
                                    <Link to="/contact"
                                        className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white mt-2 rounded-md"><i
                                            className="uil uil-envelope"></i> Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <JobCounter />
                <QuesAnswer />
                {/* <div className="container-fluid md:mt-24 mt-16">
                    <div className="container">
                        <div className="grid grid-cols-1">
                            <div className="relative overflow-hidden lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700">
                                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                    <div className="lg:col-span-8 md:col-span-7">
                                        <div className="ltr:md:text-left rtl:md:text-right text-center relative z-1">
                                            <h3 className="text-2xl font-semibold text-black dark:text-white mb-4">How do I enrol?
                                            </h3>
                                            <p className="max-w-xl" style={customStyle}>To enrol in the HAPPY program, simply click the button register to get started. We look forward to welcoming you to our network of partners and beneficiaries.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-4 md:col-span-5">
                                        <div className="ltr:text-right rtl:text-left relative z-1">
                                            <Link to="https://forms.office.com/pages/responsepage.aspx?id=tKEMAICS1EuGFsd78LKszzCOb_qsI_xErZBJYlaIb4tUM1ZTVlNUUVgwVUdIMFBOTk5ITUtDR0JCNS4u"
                                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md" target='_blank'>
                                                Register</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <div
                                        className="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45">
                                    </div>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                    <div className="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="container-fluid md:mt-24 mt-16">
                    <div className="container">
                        <div className="grid grid-cols-1">
                            <div className="relative overflow-hidden lg:px-8 px-6 py-10 rounded-xl shadow-lg dark:shadow-gray-700">
                                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                    <div className="lg:col-span-8 md:col-span-7">
                                        <div className="ltr:md:text-left rtl:md:text-right text-center relative z-1">
                                            <h3 className="text-2xl font-semibold text-black dark:text-white mb-4">Talk to us
                                            </h3>
                                            <p className="max-w-xl" style={customStyle}>Whether you're interested in speaking to our country management team, enquiring about one of our products or partnering with us, we want to hear from you.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-4 md:col-span-5">
                                        <div className="ltr:text-right rtl:text-left relative z-1">
                                            <Link to="/contact"
                                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md">
                                                Contact US</Link>
                                            <Link to="/aboutus"
                                                className="btn bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white rounded-md ms-2">Learn
                                                More</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <div
                                        className="uil uil-envelope lg:text-[150px] text-7xl text-black/5 dark:text-white/5 ltr:-rotate-45 rtl:rotate-45">
                                    </div>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                    <div className="uil uil-pen lg:text-[150px] text-7xl text-black/5 dark:text-white/5 rtl:-rotate-90">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} />
            </section>
            <Footer />
        </>
    )
}
