import React from 'react'
import Navbar from '../../components/Navbar';
import Partner from '../Partner';
import PartnerValueChain from './PartnerValueChain';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const Technoserve = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Jobs', Rice: '3,200', Soybean: '3,840', Tomato: '2,560', Poultry: '33,840', Total: '13,440' },
    ];
    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Technoserve</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="TechnoServe (TNS)"
                    description="TechnoServe (TNS) is a leading non-profit organization operating in almost 30 countries, supporting women and men to build competitive farms, businesses, and industries. The firm  also assist in linking people to information, capital, and markets." />
                <Partner
                    description="Under the HAPPY Program, TechnoServe will directly support 24 agri-SMEs in the four target value chains as well as ag-adjacent sectors. The firm is expected to provide at least 15,540 dignified and fulfilling jobs for youth by the end of four years." />
                <Partner
                    description="TechnoServe will provide value chain facilitation support across all value chains delivering core business technical assistance to selected SMEs and work with them as off takers to implement inclusive business plans that enhance their operations. " />
                <Partner
                    description="TechnoServe will manage a micro-grant that seeks to provide start-up micro financial support to selected enterprises. It will also manage catalytic fund to be administered to selected SMEs for business reengineering support as well as an innovation grant to provide micro grant support to selected enterprises for persons with disability and pilot testing crop insurance in selected high risk project areas.  TechnoServe will also lead gender mainstreaming action plan of the project and further conduct a gender training for partners to ensure that they are equipped to promote gender equity in program activities." />
                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Technoserve