import React, { useState } from 'react'
import ab05 from '../assets/images/about/ab05.jpg';
import Navbar from '../components/Navbar'
import MillionsJob from '../components/Millions-job'
import Feature from '../components/Feature'
import JobCounter from '../components/Job-counter';
import QuesAnswer from '../components/Question-Answer';
import ExploreJob from '../components/Explore-job';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function Aboutus() {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10"></div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container md:pb-16">
                    <MillionsJob />
                </div>

                <Feature />
                <div className="container md:mt-24 mt-16">
                    <div className="grid grid-cols-1">
                        <div className="relative overflow-hidden bg-emerald-600 rounded-md shadow dark:shadow-gray-700">
                            <div className="grid md:grid-cols-2 items-center gap-[30px]">
                                <div className="relative">
                                    <img src={ab05} alt="" />
                                    <div className="absolute md:bottom-1/2 md:translate-y-1/2 md:-end-10 ltr:md:translate-x-0 rtl:md:translate-x-0 -bottom-10 end-1/2 ltr:translate-x-1/2 rtl:-translate-x-1/2 text-center">
                                        <Link to="#" style={{ pointerEvents: 'none' }}  onClick={() => setOpen(true)} data-id="yba7hPeTSjk" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-emerald-600 dark:text-white">
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div>
                                    <div className="text-white p-4">
                                        <h4 className="leading-normal text-4xl mb-3 font-semibold">Numerous Job Opportunities</h4>
                                        <ul className="list-none text-white/50 mt-4">
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Seed, Broiler Entrepreneurs</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Nursery management</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Business Advisory</li>

                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Agro-input retailers / dealers</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Feed supply</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Producers</li>

                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Mechanization service operators</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Community Based Advisors</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Gang operators</li>

                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Processors</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Aggregators</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Slaughter Services</li>

                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Marketers</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i>  Retailers / Wholesalers</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Transporters</li>

                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i> Weighing</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i>Packaging & Labeling</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i>Drone Tech Services</li>
                                            <li className="mb-1 flex" style={{ color: '#fff' }}><i className="uil uil-check-circle text-white text-xl me-2"></i>Business technical services</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 md:mt-24 mt-16">
                    <JobCounter />
                </div>
                {/* <QuesAnswer /> */}
                <ExploreJob />
            </section>
            <Footer />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} />

        </>
    )
}