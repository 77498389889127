import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import { notification, Table, Button, Select, Form, Input, Modal, DatePicker, Popconfirm, } from "antd";
import { editNav, getNav } from '../service/hamis.navbar';
import { getHomeBanner, updateBanner } from '../service/homebanner.service';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { getOverview } from '../service/overview.service';
import { getRegister, updateRegister } from '../service/register.service';
import { getTalk, updateTalk } from '../service/talk.service';
import { getEnroll, updateEnroll } from '../service/enroll.service';
import { getJobFigures, getJobOpportunity, updateJobFigures } from '../service/job.service';
import { getFooter, updateFooterData } from '../service/footer.service';


const { TabPane } = Tabs;
function callback(key) {
    console.log(key);
}

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const Dashboard = () => {
    const [isUpdatePostModalVisible, setIsUpdatePostModalVisible] = useState(false);
    const [isUpdateBannerModalVisible, setIsUpdateBannerModalVisible] = useState(false);
    const [isUpdateRegisterModalVisible, setIsUpdateRegisterModalVisible] = useState(false);
    const [isUpdateTalkModalVisible, setIsUpdateTalkModalVisible] = useState(false);
    const [isUpdateEnrolModalVisible, setIsUpdateEnrolModalVisible] = useState(false);
    const [isUpdateJobFigureModalVisible, setIsUpdateJobFigureModalVisible] = useState(false);
    const [isUpdateFooterModalVisible, setIsUpdateFooterModalVisible] = useState(false);
    const [isUpdateJobModalVisible, setIsUpdateJobModalVisible] = useState(false);
    const [isUpdateOverviewModalVisible, setIsUpdateOverviewModalVisible] = useState(false);
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [isViewBannerModalVisible, setIsViewBannerModalVisible] = useState(false);
    const [isViewOverviewModalVisible, setIsViewOverviewModalVisible] = useState(false);
    const [isViewRegisterModalVisible, setIsViewRegisterModalVisible] = useState(false);
    const [isViewTalkModalVisible, setIsViewTalkModalVisible] = useState(false);
    const [isViewEnrolModalVisible, setIsViewEnrolModalVisible] = useState(false);
    const [isViewJobFigureModalVisible, setIsViewJobFigureModalVisible] = useState(false);
    const [isViewFooterModalVisible, setIsViewFooterModalVisible] = useState(false);
    const [isViewJobModalVisible, setIsViewJobModalVisible] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [selectedOverview, setSelectedOverview] = useState(null);
    const [selectedRegister, setSelectedRegister] = useState(null);
    const [selectedTalk, setSelectedTalk] = useState(null);
    const [selectedEnrol, setSelectedEnrol] = useState(null);
    const [selectedJobFigure, setSelectedJobFigure] = useState(null);
    const [selectedFooter, setSelectedFooter] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [form] = Form.useForm();
    const [nav, setNav] = useState([])
    const [banner, setBanner] = useState([])
    const [overview, setOverview] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [register, setRegister] = useState([])
    const [talk, setTalk] = useState([])
    const [enrol, setEnrol] = useState([])
    const [jobFigure, setJobFigure] = useState([])
    const [footer, setFooter] = useState([])
    const [jobs, setJobs] = useState([])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('files[]', file);
        });
        setUploading(true);
        // You can use any AJAX library you like
        fetch('https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload', {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then(() => {
                setFileList([]);
                message.success('upload successfully.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const [tableCols] = useState([
        {
            key: "home",
            title: "Home",
            dataIndex: "home",
        },
        {
            key: "aboutUs",
            title: "About Us",
            dataIndex: "aboutUs",
        },
        {
            key: "valueChain",
            title: "Value Chain",
            dataIndex: "valueChain",
        },
        {
            key: "hamis",
            title: "HAMIS",
            dataIndex: "hamis",
        },
        {
            key: "register",
            title: "Register",
            dataIndex: "register",
        },
        {
            key: "contactUs",
            title: "Contact",
            dataIndex: "contactUs",
        },
        {
            key: "masterCardLogo",
            title: "MasterCard Logo",
            dataIndex: "masterCardLogo",
        },
        {
            key: "agriImpactLogo",
            title: "AgriImpact Logo",
            dataIndex: "agriImpactLogo",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editPost(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedPost(post);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const [BannertableCols] = useState([
        {
            key: "title",
            title: "Title",
            dataIndex: "title",
        },
        {
            key: "description",
            title: "Description",
            dataIndex: "description",
        },
        {
            key: "button",
            title: "Button Text",
            dataIndex: "button",
        },
        {
            key: "imageUrl",
            title: "Image URLs",
            dataIndex: "imageUrl",
            render: (urls) => urls.map(urlObj => urlObj.imageUrl).join(', ')
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editBanner(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedBanner(post);
                        setIsViewBannerModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                <Button
                    style={{ marginRight: "7px", color: "#0074D9" }}
                    onClick={showModal}>
                    Upload Image
                </Button>
            ],
        },
    ]);

    const [tableColsOverView] = useState([
        {
            key: "title",
            title: "Title",
            dataIndex: "title",
        },
        {
            key: "description",
            title: "Description",
            dataIndex: "description",
        },
        // {
        //     key: "descriptionList",
        //     title: "Description List",
        //     dataIndex: "descriptionList",
        //     render: (description) => description.map(descriptionObj => descriptionObj.descriptionList).join(', ')
        // },
        // {
        //     key: "targetItems",
        //     title: "Target Items",
        //     dataIndex: "targetItems",
        //     render: (item) => item.map(targetItemsObj => targetItemsObj.descriptionList).join(', ')
        // },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editOverview(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedOverview(post);
                        setIsViewOverviewModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const [registertableCols] = useState([
        {
            key: "registerTitle",
            title: "Title",
            dataIndex: "registerTitle",
        },
        {
            key: "registerDescription",
            title: "Description",
            dataIndex: "registerDescription",
        },
        {
            key: "registerButton",
            title: "Button Text",
            dataIndex: "registerButton",
        },
        {
            key: "backgroundImageUrl",
            title: "Image URLs",
            dataIndex: "backgroundImageUrl",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editRegister(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedRegister(post);
                        setIsViewRegisterModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                // <Button
                //     style={{ marginRight: "7px", color: "#0074D9" }}
                //     onClick={showModal}>
                //     Upload Image
                // </Button>
            ],
        },
    ]);

    const [talktableCols] = useState([
        {
            key: "talkToUsTitle",
            title: "Title",
            dataIndex: "talkToUsTitle",
        },
        {
            key: "talkToUsDescription",
            title: "Description",
            dataIndex: "talkToUsDescription",
        },
        {
            key: "talkToUsContactButton",
            title: "Button Text",
            dataIndex: "talkToUsContactButton",
        },
        {
            key: "talkToUsLearnMoreButton",
            title: "Learn More Button Text",
            dataIndex: "talkToUsLearnMoreButton",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editTalk(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedTalk(post);
                        setIsViewTalkModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const [enroltableCols] = useState([
        {
            key: "frequentlyAskedQuestionsTitle",
            title: "Frequently Asked QuestionsTitle",
            dataIndex: "frequentlyAskedQuestionsTitle",
        },
        {
            key: "frequentlyAskedQuestionsDescription",
            title: "Frequently Asked Questions Description",
            dataIndex: "frequentlyAskedQuestionsDescription",
        },
        {
            key: "enrollTitle",
            title: "Title",
            dataIndex: "enrollTitle",
        },
        {
            key: "enrollDescription",
            title: "Description",
            dataIndex: "enrollDescription",
        },
        {
            key: "enrollButton",
            title: "Button Text",
            dataIndex: "enrollButton",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editEnrol(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedEnrol(post);
                        setIsViewEnrolModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const [jobFiguretableCols] = useState([
        {
            key: "type",
            title: "Title",
            dataIndex: "type",
        },
        {
            key: "value",
            title: "Value",
            dataIndex: "value",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editJobFigure(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedJobFigure(post);
                        setIsViewJobFigureModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const [footertableCols] = useState([
        {
            key: "footerHome",
            title: "Footer Home",
            dataIndex: "footerHome",
        },
        {
            key: "footerTermsAndConditions",
            title: "Terms",
            dataIndex: "footerTermsAndConditions",
        },
        {
            key: "footerAbout",
            title: "About",
            dataIndex: "footerAbout",
        },
        {
            key: "footerContactUs",
            title: "Contact",
            dataIndex: "footerContactUs",
        },
        {
            key: "report",
            title: "Report",
            dataIndex: "report",
        },
        {
            key: "footerCopyRight",
            title: "CopyRight",
            dataIndex: "footerCopyRight",
        },
        {
            key: "footerFacebookLink",
            title: "Facebook",
            dataIndex: "footerFacebookLink",
        },
        {
            key: "footerTwitterLink",
            title: "Twitter",
            dataIndex: "footerTwitterLink",
        },
        {
            key: "footerInstagramLink",
            title: "Instagram",
            dataIndex: "footerInstagramLink",
        },
        {
            key: "footerLinkedInLink",
            title: "LinkedIn",
            dataIndex: "footerLinkedInLink",
        },
        {
            key: "footerImageUrl",
            title: "Image URL",
            dataIndex: "footerImageUrl",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button
                    onClick={() => editFooter(post)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedFooter(post);
                        setIsViewFooterModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const [jobstableCols] = useState([
        {
            key: "jobTitle",
            title: "Title",
            dataIndex: "jobTitle",
        },
        {
            key: "jobDescription",
            title: "Description",
            dataIndex: "jobDescription",
        },
        {
            key: "jobContactButton",
            title: "Contact Button",
            dataIndex: "jobContactButton",
        },
        {
            key: "jobList",
            title: "Job List",
            dataIndex: "jobList", render: (job) => job.map(jobObj => jobObj.jobTitle).join(', ')
        },
        {
            key: "jobImageUrl",
            title: "Image URL",
            dataIndex: "jobImageUrl",
        },
        {
            key: "action", title: "Action", align: "center",
            render: (post) => [
                <Button onClick={() => editJob(post)} style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedJob(post);
                        setIsViewJobModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
            ],
        },
    ]);

    const getNavData = () => {
        getNav().then(res => {
            setNav(res.data)
            console.log('Nav data', res.data)
        }).catch(err => {
            console.error(err);
        });
    }

    const getHomeBannerData = () => {
        getHomeBanner()
            .then(res => {
                // If res.data is an object
                if (res.data && typeof res.data === 'object') {
                    setBanner([res.data])
                } else {
                    setBanner([])
                }
            }).catch(err => {
                console.error(err);
            });
    }

    const getOverviewData = async () => {
        getOverview().then(res => {
            setOverview(res.data)
        }).catch(err => {
            console.error(err);
        });
    }

    const getRegisterData = () => {
        getRegister()
            .then(res => {
                if (res.data && typeof res.data === 'object') {
                    setRegister([res.data])
                } else {
                    setRegister([])
                }
            }).catch(err => {
                console.error(err);
            });
    }

    const getTalkData = () => {
        getTalk().then(res => {
            if (res.data && typeof res.data === 'object') {
                setTalk([res.data])
            } else {
                setTalk([])
            }
        }).catch(err => {
            console.error(err);
        });
    }

    const getEnrollData = () => {
        getEnroll().then(res => {
            setEnrol(res.data)
        })
    }

    const getJobFigureData = () => {
        getJobFigures().then(res => {
            console.log('Job Figure data', res.data)
            setJobFigure(res.data)
        })
    }

    const getFooterData = () => {
        getFooter().then(res => {
            if(res.data && typeof res.data === 'object'){
                setFooter([res.data])
            }
            else{
                setFooter([])
            }

        })
    }

    const getJobData = () => {
        getJobOpportunity().then(res => {
            if(res.data && typeof res.data === 'object'){
                setJobs([res.data])
            }else{
                setJobs([])
            }
        })
    }

    useEffect(() => {
        getNavData()
        getHomeBannerData()
        getOverviewData()
        getRegisterData()
        getTalkData()
        getEnrollData()
        getJobFigureData()
        getFooterData()
        getJobData()
    }, [])

    const updateNavBar = () => {
        const newValues = {
            home: form.getFieldValue("home"),
            aboutUs: form.getFieldValue("aboutUs"),
            valueChain: form.getFieldValue("valueChain"),
            hamis: form.getFieldValue("hamis"),
            register: form.getFieldValue("register"),
            contactUs: form.getFieldValue("contact"),
            masterCardLogo: form.getFieldValue("masterCardLogo"),
            agriImpactLogo: form.getFieldValue("agriImpactLogo"),
        };

        // Only keep the fields that have changed
        const changedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== nav[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        // Send the update
        editNav(selectedPost.id, changedValues).then(res => {
            notification.success({
                message: "Post updated successfully",
            });
            getNavData();
            setIsUpdatePostModalVisible(false);
        }).catch(err => {
            console.error(err);
            notification.error({
                message: "An error occurred",
            });
        });
    };

    const updateBannerDetails = () => {
        let cons = banner[0].imageUrl.map((urlObj) => ({ imageUrl: urlObj.imageUrl }))
        const newValues = {
            title: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
            button: form.getFieldValue("button"),
            imageUrl: cons,
        };

        const bannerChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== banner[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateBanner(selectedBanner.id, bannerChangedValues)
            .then(res => {
                notification.success({
                    message: "Banner updated successfully",
                });
                getHomeBannerData();
                setIsUpdateBannerModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const updateRegisterDetails = () => {
        const newValues = {
            registerTitle: form.getFieldValue("registerTitle"),
            registerDescription: form.getFieldValue("registerDescription"),
            registerButton: form.getFieldValue("registerButton"),
            backgroundImageUrl: form.getFieldValue("backgroundImageUrl"),
        };

        const registerChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== register[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateRegister(selectedRegister.id, registerChangedValues)
            .then(res => {
                notification.success({
                    message: "Register updated successfully",
                });
                getRegisterData();
                setIsUpdateRegisterModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const updateTalkDetails = () => {
        const newValues = {
            talkToUsTitle: form.getFieldValue("talkToUsTitle"),
            talkToUsDescription: form.getFieldValue("talkToUsDescription"),
            talkToUsContactButton: form.getFieldValue("talkToUsContactButton"),
            talkToUsLearnMoreButton: form.getFieldValue("talkToUsLearnMoreButton"),
        };

        const talkChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== talk[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateTalk(selectedTalk.id, talkChangedValues)
            .then(res => {
                notification.success({
                    message: "Talk updated successfully",
                });
                getTalkData();
                setIsUpdateTalkModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const updateEnrolDetails = () => {
        const newValues = {
            frequentlyAskedQuestionsTitle: form.getFieldValue("frequentlyAskedQuestionsTitle"),
            frequentlyAskedQuestionsDescription: form.getFieldValue("frequentlyAskedQuestionsDescription"),
            enrollTitle: form.getFieldValue("enrollTitle"),
            enrollDescription: form.getFieldValue("enrollDescription"),
            enrollButton: form.getFieldValue("enrollButton"),
        };

        const enrolChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== enrol[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateEnroll(selectedEnrol.id, enrolChangedValues)
            .then(res => {
                notification.success({
                    message: "Enroll updated successfully",
                });
                getEnrollData();
                setIsUpdateEnrolModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const updateJobFigureDetails = () => {
        const newValues = {
            type: form.getFieldValue("type"),
            value: form.getFieldValue("value"),
        };

        const jobFigureChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== jobFigure[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateJobFigures(selectedJobFigure.id, [jobFigureChangedValues])
            .then(res => {
                notification.success({
                    message: "Job Figure updated successfully",
                });
                getJobFigureData();
                setIsUpdateJobFigureModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const updateFooterDetails = () => {
        const newValues = {
            footerHome: form.getFieldValue("footerHome"),
            footerTermsAndConditions: form.getFieldValue("footerTermsAndConditions"),
            footerAbout: form.getFieldValue("footerAbout"),
            footerContactUs: form.getFieldValue("footerContactUs"),
            report: form.getFieldValue("report"),
            footerCopyRight: form.getFieldValue("footerCopyRight"),
            footerFacebookLink: form.getFieldValue("footerFacebookLink"),
            footerTwitterLink: form.getFieldValue("footerTwitterLink"),
            footerInstagramLink: form.getFieldValue("footerInstagramLink"),
            footerLinkedInLink: form.getFieldValue("footerLinkedInLink"),
            footerImageUrl: form.getFieldValue("footerImageUrl"),
        };

        const footerChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== footer[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateFooterData(selectedFooter.id, footerChangedValues)
            .then(res => {
                notification.success({
                    message: "Footer updated successfully",
                });
                getFooterData();
                setIsUpdateFooterModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const updateJobDetails = () => {
        const newValues = {
            jobTitle: form.getFieldValue("jobTitle"),
            jobDescription: form.getFieldValue("jobDescription"),
            jobContactButton: form.getFieldValue("jobContactButton"),
            jobList: form.getFieldValue("jobList"),
            jobImageUrl: form.getFieldValue("jobImageUrl"),
        };

        const jobChangedValues = Object.keys(newValues).reduce((result, key) => {
            if (newValues[key] !== jobs[key]) {
                result[key] = newValues[key];
            }
            return result;
        }, {});

        updateJobOpportunity(selectedJob.id, jobChangedValues)
            .then(res => {
                notification.success({
                    message: "Job updated successfully",
                });
                getJobData();
                setIsUpdateJobModalVisible(false);
            }).catch(err => {
                console.error(err);
                notification.error({
                    message: "An error occurred",
                });
            });
    };

    const onUpdatePostCancel = () => {
        setIsUpdatePostModalVisible(false);
    };

    const onUpdateOverviewCancel = () => {
        setIsUpdateOverviewModalVisible(false);
    };

    const editPost = (post) => {
        setSelectedPost(post);
        form?.setFieldsValue({
            home: post.home,
            aboutUs: post.aboutUs,
            valueChain: post.valueChain,
            hamis: post.hamis,
            register: post.register,
            contact: post.contactUs,
            masterCardLogo: post.masterCardLogo,
            agriImpactLogo: post.agriImpactLogo,
        });
        setIsUpdatePostModalVisible(true);
    };

    const editBanner = (post) => {
        setSelectedBanner(post);
        form?.setFieldsValue({
            title: post.title,
            description: post.description,
            button: post.button,
        });
        setIsUpdateBannerModalVisible(true);
    };

    const editRegister = (post) => {
        setSelectedBanner(post);
        form?.setFieldsValue({
            registerTitle: post.registerTitle,
            registerDescription: post.registerDescription,
            registerButton: post.registerButton,
            backgroundImageUrl: post.backgroundImageUrl,
        });
        setIsUpdateRegisterModalVisible(true);
    };

    const editTalk = (post) => {
        setSelectedTalk(post);
        form?.setFieldsValue({
            talkToUsTitle: post.talkToUsTitle,
            talkToUsDescription: post.talkToUsDescription,
            talkToUsContactButton: post.talkToUsContactButton,
            talkToUsLearnMoreButton: post.talkToUsLearnMoreButton,
        });
        setIsUpdateTalkModalVisible(true);
    };

    const editEnrol = (post) => {
        setSelectedEnrol(post);
        form?.setFieldsValue({
            frequentlyAskedQuestionsTitle: post.frequentlyAskedQuestionsTitle,
            frequentlyAskedQuestionsDescription: post.frequentlyAskedQuestionsDescription,
            enrollTitle: post.enrollTitle,
            enrollDescription: post.enrollDescription,
            enrollButton: post.enrollButton,
        });
        setIsUpdateEnrolModalVisible(true);
    };

    const editJobFigure = (post) => {
        setSelectedJobFigure(post);
        form?.setFieldsValue({
            type: post.type,
            value: post.value,
        });
        setIsUpdateJobFigureModalVisible(true);
    };

    const editFooter = (post) => {
        setSelectedFooter(post);
        form?.setFieldsValue({
            footerHome: post.footerHome,
            footerTermsAndConditions: post.footerTermsAndConditions,
            footerAbout: post.footerAbout,
            footerContactUs: post.footerContactUs,
            report: post.report,
            footerCopyRight: post.footerCopyRight,
            footerFacebookLink: post.footerFacebookLink,
            footerTwitterLink: post.footerTwitterLink,
            footerInstagramLink: post.footerInstagramLink,
            footerLinkedInLink: post.footerLinkedInLink,
            footerImageUrl: post.footerImageUrl,
        });
        setIsUpdateFooterModalVisible(true);
    };
    
    const editJob = (post) => {
        setSelectedJob(post);
        form?.setFieldsValue({
            jobTitle: post.jobTitle,
            jobDescription: post.jobDescription,
            jobContactButton: post.jobContactButton,
            jobList: post.jobList,
            jobImageUrl: post.jobImageUrl,
        });
        setIsUpdateJobModalVisible(true);
    };

    const editOverview = (post) => {
        setSelectedOverview(post);
        form?.setFieldsValue({
            title: post.title,
            description: post.description,
            descriptionList: post.descriptionList,
            targetItems: post.targetItems,
        });
        setIsUpdateOverviewModalVisible(true);
    };

    const onUpdateBannerCancel = () => {
        setIsUpdateBannerModalVisible(false);
    }
    const onUpdateRegisterCancel = () => {
        setIsUpdateRegisterModalVisible(false);
    }
    const onUpdateTalkCancel = () => {
        setIsUpdateTalkModalVisible(false);
    }

    const onUpdateEnrolCancel = () => {
        setIsUpdateEnrolModalVisible(false);
    }

    const onUpdateJobFigureCancel = () => {
        setIsUpdateJobFigureModalVisible(false);
    }

    const onUpdateFooterCancel = () => {
        setIsUpdateFooterModalVisible(false);
    }

    const onUpdateJobCancel = () => {
        setIsUpdateJobModalVisible(false);
    }

    return (
        <div>
            <div style={{ textAlign: 'center', paddingTop: '2rem', paddingBottom: '2rem' }}>
                <h2>Welcome to HAMIS Content Managment</h2>
            </div>
            <div style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                {/* Modal upload image  */}
                <Modal
                    title="Upload Image for Banner Carousel"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={[
                        <Button onClick={handleCancel}>Cancel</Button>,
                        <Button
                            key="ok"
                            style={{ marginRight: "7px", color: "#0074D9" }}
                            onClick={handleOk}
                        >
                            Close
                        </Button>,
                    ]}
                >
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                    <Button
                        onClick={handleUpload}
                        disabled={fileList.length === 0}
                        loading={uploading}
                        style={{
                            marginTop: 16,
                            color: "#0074D9"
                        }}
                    >
                        {uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                </Modal>
                <Tabs onChange={callback} type="card">
                    <TabPane tab="NavBar" key="1">
                        {/* modals edit */}
                        <Modal
                            open={isUpdatePostModalVisible}
                            onCancel={onUpdatePostCancel}
                            footer={[
                                <Button onClick={onUpdatePostCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateNavBar}
                                >
                                    Post changes
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"home"}
                                    label="Home"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"aboutUs"}
                                    label="About Us"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"valueChain"}
                                    label="Value Chain"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"hamis"}
                                    label="HAMIS"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"register"}
                                    label="Register"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"contact"}
                                    label="Contact"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"masterCardLogo"}
                                    label="MasterCard Logo"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"agriImpactLogo"}
                                    label="AgriImpact Logo"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view  */}
                        <Modal
                            open={isViewPostModalVisible}
                            onCancel={() => {
                                setIsViewPostModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => {
                                        setIsViewPostModalVisible(false);
                                    }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewPostModalVisible(false);
                                        editPost(selectedPost);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"home"} label="Home">
                                    <p style={{ margin: 0 }}>{selectedPost?.home}</p>
                                </Form.Item>

                                <Form.Item name={"aboutUs"} label="About Us">
                                    <p style={{ margin: 0 }}>{selectedPost?.aboutUs}</p>
                                </Form.Item>

                                <Form.Item name={"valueChain"} label="Value Chain">
                                    <p style={{ margin: 0 }}>{selectedPost?.valueChain}</p>
                                </Form.Item>

                                <Form.Item name={"hamis"} label="HAMIS">
                                    <p style={{ margin: 0 }}>{selectedPost?.hamis}</p>
                                </Form.Item>

                                <Form.Item name={"register"} label="Register">
                                    <p style={{ margin: 0 }}>{selectedPost?.register}</p>
                                </Form.Item>
                                <Form.Item name={"contactUs"} label="Contact">
                                    <p style={{ margin: 0 }}>{selectedPost?.contactUs}</p>
                                </Form.Item>
                                <Form.Item name={"masterCardLogo"} label="MasterCard Logo">
                                    <p style={{ margin: 0 }}>{selectedPost?.masterCardLogo}</p>
                                </Form.Item>
                                <Form.Item name={"agriImpactLogo"} label="AgriImpact Logo">
                                    <p style={{ margin: 0 }}>{selectedPost?.agriImpactLogo}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={tableCols} dataSource={nav} />
                    </TabPane>
                    <TabPane tab="Home Banner" key="2">
                        {/* modals edit banner */}
                        <Modal
                            open={isUpdateBannerModalVisible}
                            onCancel={onUpdateBannerCancel}
                            footer={[
                                <Button onClick={onUpdateBannerCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateBannerDetails}
                                >
                                    Update Banner
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"title"}
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"description"}
                                    label="Descritipn"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"button"}
                                    label="Button Text"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view  */}
                        <Modal
                            open={isViewBannerModalVisible}
                            onCancel={() => {
                                setIsViewBannerModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => {
                                        setIsViewBannerModalVisible(false);
                                    }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewBannerModalVisible(false);
                                        editBanner(selectedBanner);
                                    }}
                                >
                                    Edit Banner{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"title"} label="Title">
                                    <p style={{ margin: 0 }}>{selectedBanner?.title}</p>
                                </Form.Item>
                                <Form.Item name={"description"} label="Descritpion">
                                    <p style={{ margin: 0 }}>{selectedBanner?.description}</p>
                                </Form.Item>
                                <Form.Item name={"button"} label="Button Text">
                                    <p style={{ margin: 0 }}>{selectedBanner?.button}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={BannertableCols} dataSource={banner} />
                        {/* <Table columns={BannertableCols} dataSource={Array.isArray(banner) ? banner : []} /> */}
                    </TabPane>
                    <TabPane tab="Overview" key="3">
                        {/* modals edit overview */}
                        <Modal
                            open={isUpdateOverviewModalVisible}
                            onCancel={onUpdateOverviewCancel}
                            footer={[
                                <Button onClick={onUpdateOverviewCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                // onClick={updateNavBar}
                                >
                                    Update Overview
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"title"}
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"description"}
                                    label="description"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                {/* <Form.Item
                                    name={"descriptionList"}
                                    label="Description List"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"targetItems"}
                                    label="Target Items"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item> */}
                            </Form>
                        </Modal>

                        {/* modal view  */}
                        <Modal
                            open={isViewOverviewModalVisible}
                            onCancel={() => {
                                setIsViewOverviewModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => {
                                        setIsViewOverviewModalVisible(false);
                                    }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewOverviewModalVisible(false);
                                        // editPost(selectedPost);
                                    }}
                                >
                                    Edit Overview{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"title"} label="Title">
                                    <p style={{ margin: 0 }}>{selectedOverview?.title}</p>
                                </Form.Item>

                                <Form.Item name={"description"} label="Description">
                                    <p style={{ margin: 0 }}>{selectedOverview?.description}</p>
                                </Form.Item>

                                {/* <Form.Item name={"descriptionList"} label="Description List">
                                    <p style={{ margin: 0 }}>{selectedOverview?.descriptionList}</p>
                                </Form.Item>

                                <Form.Item name={"targetItems"} label="Target Items">
                                    <p style={{ margin: 0 }}>{selectedPost?.targetItems}</p>
                                </Form.Item> */}
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={tableColsOverView} dataSource={overview} />
                        {/* <Table columns={tableColsOverView} dataSource={Array.isArray(overview) ? overview : []} /> */}
                    </TabPane>
                    <TabPane tab="Footprints" key="4">
                        Content of Tab Pane 4
                    </TabPane>
                    <TabPane tab="Register" key="5">
                        {/* modals edit register */}
                        <Modal
                            open={isUpdateRegisterModalVisible}
                            onCancel={onUpdateRegisterCancel}
                            footer={[
                                <Button onClick={onUpdateRegisterCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateRegisterDetails}
                                >
                                    Update Register
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"registerTitle"}
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"registerDescription"}
                                    label="Descritipn"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"registerButton"}
                                    label="Button Text"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view  */}
                        <Modal
                            open={isViewRegisterModalVisible}
                            onCancel={() => {
                                setIsViewRegisterModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => { setIsViewRegisterModalVisible(false); }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewRegisterModalVisible(false);
                                        editRegister(selectedRegister);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"registerTitle"} label="Title">
                                    <p style={{ margin: 0 }}>{selectedRegister?.registerTitle}</p>
                                </Form.Item>
                                <Form.Item name={"registerDescription"} label="Descritpion">
                                    <p style={{ margin: 0 }}>{selectedRegister?.registerDescription}</p>
                                </Form.Item>
                                <Form.Item name={"registerButton"} label="Button Text">
                                    <p style={{ margin: 0 }}>{selectedRegister?.registerButton}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={registertableCols} dataSource={register} />
                    </TabPane>
                    <TabPane tab="Partners" key="6">
                        Content of Tab Pane 5
                    </TabPane>
                    <TabPane tab="Job Opportunities" key="7">
                        {/* modals edit footer */}
                        <Modal
                            open={isUpdateJobModalVisible}
                            onCancel={onUpdateJobCancel}
                            footer={[
                                <Button onClick={onUpdateJobCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateJobDetails}
                                >
                                    Update Job
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"footerHome"}
                                    label="Footer Home"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"jobTitle"}
                                    label="Opportunity List"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"jobDescription"}
                                    label="Description"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"jobContactButton"}
                                    label="Description"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"jobList"}
                                    label="Job List"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"jobImageUrl"}
                                    label="Image Url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view */}
                        <Modal
                            open={isViewJobModalVisible}
                            onCancel={() => {
                                setIsViewJobModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => { setIsViewJobModalVisible(false); }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewJobModalVisible(false);
                                        editJob(selectedJob);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"jobTitle"} label="Job Title">
                                    <p style={{ margin: 0 }}>{selectedJob?.jobTitle}</p>
                                </Form.Item>
                                <Form.Item name={"jobDescription"} label="Job Description">
                                    <p style={{ margin: 0 }}>{selectedJob?.jobDescription}</p>
                                </Form.Item>
                                <Form.Item name={"jobContactButton"} label="Contact Button">
                                    <p style={{ margin: 0 }}>{selectedJob?.jobContactButton}</p>
                                </Form.Item>
                                <Form.Item name={"jobList"} label="Job List">
                                    <p style={{ margin: 0 }}>{selectedJob?.jobList}</p>
                                </Form.Item>
                                <Form.Item name={"jobImageUrl"} label="Image Url">
                                    <p style={{ margin: 0 }}>{selectedJob?.jobImageUrl}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={jobstableCols} dataSource={jobs} />
                    </TabPane>
                    <TabPane tab="How to Enrol" key="8">
                        {/* modals edit enroll */}
                        <Modal
                            open={isUpdateEnrolModalVisible}
                            onCancel={onUpdateEnrolCancel}
                            footer={[
                                <Button onClick={onUpdateEnrolCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateEnrolDetails}
                                >
                                    Update Enroll
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"frequentlyAskedQuestionsTitle"}
                                    label="Frequent Questions"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"frequentlyAskedQuestionsDescription"}
                                    label="Frequent Description"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"enrollTitle"}
                                    label="How to Enroll"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"enrollDescription"}
                                    label="Descritipn"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"enrollButton"}
                                    label="Enroll Button"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view */}
                        <Modal
                            open={isViewEnrolModalVisible}
                            onCancel={() => {
                                setIsViewEnrolModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => { setIsViewEnrolModalVisible(false); }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewEnrolModalVisible(false);
                                        editEnrol(selectedEnrol);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"frequentlyAskedQuestionsTitle"} label="Frequently Title">
                                    <p style={{ margin: 0 }}>{selectedEnrol?.frequentlyAskedQuestionsTitle}</p>
                                </Form.Item>
                                <Form.Item name={"frequentlyAskedQuestionsDescription"} label="Frequently Description">
                                    <p style={{ margin: 0 }}>{selectedEnrol?.frequentlyAskedQuestionsDescription}</p>
                                </Form.Item>
                                <Form.Item name={"enrollTitle"} label="How To Enrol">
                                    <p style={{ margin: 0 }}>{selectedEnrol?.enrollTitle}</p>
                                </Form.Item>
                                <Form.Item name={"enrollDescription"} label="Descritpion">
                                    <p style={{ margin: 0 }}>{selectedEnrol?.enrollDescription}</p>
                                </Form.Item>
                                <Form.Item name={"enrollButton"} label="Button Text">
                                    <p style={{ margin: 0 }}>{selectedEnrol?.enrollButton}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={enroltableCols} dataSource={enrol} />
                    </TabPane>
                    <TabPane tab="Talk to Us" key="9">
                        {/* modals edit talk */}
                        <Modal
                            open={isUpdateTalkModalVisible}
                            onCancel={onUpdateTalkCancel}
                            footer={[
                                <Button onClick={onUpdateTalkCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateTalkDetails}
                                >
                                    Update Talk
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"talkToUsTitle"}
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"talkToUsDescription"}
                                    label="Descritipn"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"talkToUsContactButton"}
                                    label="Contact Button"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"talkToUsLearnMoreButton"}
                                    label="Learn More"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view */}
                        <Modal
                            open={isViewTalkModalVisible}
                            onCancel={() => {
                                setIsViewTalkModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => { setIsViewTalkModalVisible(false); }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewTalkModalVisible(false);
                                        editTalk(selectedTalk);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"talkToUsTitle"} label="Title">
                                    <p style={{ margin: 0 }}>{selectedTalk?.talkToUsTitle}</p>
                                </Form.Item>
                                <Form.Item name={"talkToUsDescription"} label="Descritpion">
                                    <p style={{ margin: 0 }}>{selectedTalk?.talkToUsDescription}</p>
                                </Form.Item>
                                <Form.Item name={"talkToUsContactButton"} label="Button Text">
                                    <p style={{ margin: 0 }}>{selectedTalk?.talkToUsContactButton}</p>
                                </Form.Item>
                                <Form.Item name={"talkToUsLearnMoreButton"} label="Button Text">
                                    <p style={{ margin: 0 }}>{selectedTalk?.talkToUsLearnMoreButton}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={talktableCols} dataSource={talk} />
                    </TabPane>
                    <TabPane tab="Job Figures" key="10">
                        {/* modals edit job figures */}
                        <Modal
                            open={isUpdateJobFigureModalVisible}
                            onCancel={onUpdateJobFigureCancel}
                            footer={[
                                <Button onClick={onUpdateJobFigureCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateJobFigureDetails}
                                >
                                    Update Figure
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"type"}
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"value"}
                                    label="Value"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view */}
                        <Modal
                            open={isViewJobFigureModalVisible}
                            onCancel={() => {
                                setIsViewJobFigureModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => { setIsViewJobFigureModalVisible(false); }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewJobFigureModalVisible(false);
                                        editJobFigure(selectedJobFigure);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"type"} label="Title">
                                    <p style={{ margin: 0 }}>{selectedJobFigure?.type}</p>
                                </Form.Item>
                                <Form.Item name={"value"} label="Value">
                                    <p style={{ margin: 0 }}>{selectedJobFigure?.value}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={jobFiguretableCols} dataSource={jobFigure} />
                    </TabPane>
                    <TabPane tab="Footer" key="11">
                        {/* modals edit footer */}
                        <Modal
                            open={isUpdateFooterModalVisible}
                            onCancel={onUpdateFooterCancel}
                            footer={[
                                <Button onClick={onUpdateFooterCancel}>Cancel</Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={updateFooterDetails}
                                >
                                    Update Footer
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item
                                    name={"footerHome"}
                                    label="Footer Home"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerTermsAndConditions"}
                                    label="Terms and Conditions"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerAbout"}
                                    label="Footer About"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerContactUs"}
                                    label="Footer ContactUs"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"report"}
                                    label="Report"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerCopyRight"}
                                    label="Copy Right"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerFacebookLink"}
                                    label="Facebook Link"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerTwitterLink"}
                                    label="Twitter Link"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerInstagramLink"}
                                    label="Instagram Link"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerLinkedInLink"}
                                    label="LinkedIn Link"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={"footerImageUrl"}
                                    label="Image Url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>

                        {/* modal view */}
                        <Modal
                            open={isViewFooterModalVisible}
                            onCancel={() => {
                                setIsViewFooterModalVisible(false);
                            }}

                            footer={[
                                <Button
                                    onClick={() => { setIsViewFooterModalVisible(false); }}
                                >
                                    Cancel
                                </Button>,
                                <Button
                                    style={{ backgroundColor: "#2698FF", color: "white" }}
                                    onClick={() => {
                                        setIsViewFooterModalVisible(false);
                                        editFooter(selectedFooter);
                                    }}
                                >
                                    Edit{" "}
                                </Button>,
                            ]}
                        >
                            <Form
                                {...layout}
                                name="nest-messages"
                                form={form}
                                style={{ paddingTop: "35px" }}
                            >
                                <Form.Item name={"footerHome"} label="Footer Home">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerHome}</p>
                                </Form.Item>
                                <Form.Item name={"footerTermsAndConditions"} label="Terms & Conditions">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerTermsAndConditions}</p>
                                </Form.Item>
                                <Form.Item name={"footerAbout"} label="Footer About">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerAbout}</p>
                                </Form.Item>
                                <Form.Item name={"footerContactUs"} label="Contact Us">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerContactUs}</p>
                                </Form.Item>
                                <Form.Item name={"report"} label="Report">
                                    <p style={{ margin: 0 }}>{selectedFooter?.report}</p>
                                </Form.Item>
                                <Form.Item name={"footerCopyRight"} label="Copy Right">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerCopyRight}</p>
                                </Form.Item>
                                <Form.Item name={"footerFacebookLink"} label="Facebook Link">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerFacebookLink}</p>
                                </Form.Item>
                                <Form.Item name={"footerTwitterLink"} label="Twitter Link">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerTwitterLink}</p>
                                </Form.Item>
                                <Form.Item name={"footerInstagramLink"} label="Instagram Link">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerInstagramLink}</p>
                                </Form.Item>
                                <Form.Item name={"footerLinkedInLink"} label="LinkedIn Link">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerLinkedInLink}</p>
                                </Form.Item>
                                <Form.Item name={"footerImageUrl"} label="Image Url">
                                    <p style={{ margin: 0 }}>{selectedFooter?.footerImageUrl}</p>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {/* main content */}
                        <Table columns={footertableCols} dataSource={footer} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}


export default Dashboard