import axios from './index'

export function getNav() {
    return axios.get('/getNavBar')
}

export function editNav(id, {
    home = '',
    aboutUs = '',
    valueChain = '',
    hamis = '',
    register = '',
    contactUs = '',
    masterCardLogo = '',
    agriImpactLogo = '',

} = {}) {
    return axios.put(`/updateNavBar/${id}`, {
        home: home,
        aboutUs: aboutUs,
        valueChain: valueChain,
        hamis: hamis,
        register: register,
        contactUs: contactUs,
        masterCardLogo: masterCardLogo,
        agriImpactLogo: agriImpactLogo
    })
}