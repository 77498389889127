import React from 'react'
import { HelpCircle } from 'react-feather'
import { Link } from 'react-router-dom'
import { Button } from 'react-scroll'

const customStyle = {
    color: '#5a6674',
}

export default function QuesAnswer() {
    const QuesAnswerData = [
        {
            question: 'How do I enroll?',
            answer: 'To enrol onto the HAPPY Program, simply click the button register to get started. We look forward to welcome you as a participant.',
        },
    ]
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Frequently asked questions</h3>

                <p className="text-slate-300 max-w-xl mx-auto">
                    Have burning questions about farming, agriculture practices, or agribusiness? Look no further! Our platform is your go-to resource for insightful answers and valuable information.
                </p>
            </div>


            <div className="grid grid-cols-1 mt-8 gap-[30px]">
                {QuesAnswerData.map((item, index) => (
                    <div className="flex" key={index}>
                        <HelpCircle className="h-8 text-emerald-600 me-3" ></HelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-lg font-semibold">{item.question}<span className="text-emerald-600"></span> </h5>
                            <p style={customStyle}>{item.answer}</p>
                        </div>
                    </div>
                ))}
                <div className="lg:col-span-4 md:col-span-5" style={{marginLeft: '2.3rem'}}>
                    <div className="text-left relative z-1">
                        <Link to="https://forms.office.com/pages/responsepage.aspx?id=tKEMAICS1EuGFsd78LKszzCOb_qsI_xErZBJYlaIb4tUM1ZTVlNUUVgwVUdIMFBOTk5ITUtDR0JCNS4u"
                            className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md" target='_blank'>
                            Register</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

