import React from 'react'
import Navbar from '../../components/Navbar'
import Partner from '../Partner'
import Footer from '../../components/Footer'
import { Link } from "react-router-dom";
import PartnerValueChain from './PartnerValueChain';

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const NSS = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '51,200', Soybean: '17570', Tomato: '33,000.45', Poultry: '2,280', Total: '104,550.45' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '10,200', Soybean: '7,200', Tomato: '3000', Poultry: '1,500,000', Total: '20,400ha' },
        { 'Value Chain': 'Total Seed (feed) required(kg)' },
        { 'Value Chain': 'Total Jobs', Rice: '23,050', Soybean: '32,050', Tomato: '22,230', Poultry: '4,650', Total: '81,980' },
    ];

    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">NSS</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="Ghana National Service Scheme (GNSS)"
                    description="The Ghana National Service Scheme (GNSS) is an agency under the Ministry of Education with the mandate to train and place Ghanaian graduates in both private and public sectors of the economy for national development. The Scheme’s approach to Youth Inclusion over the past 13 years has been to establish training and demonstration model farms as centres where young people, especially women, are trained, placed, and supported in specific value chains so they can identify opportunities that will enable them to transition to jobs." />
                <Partner
                    description="GNSS will deploy 81,980 formal youth to the project, allocate 10,000ha of land for rice cultivation, 7,000ha for soybean cultivation and 3,000ha of land for tomato and invest in rice and tomato seed production and catalyze additional support for the development of these lands with irrigation, mechanization services and other small farm implements to assist the formal youth embark on intensive, profitable, and sustainable production of these value chains." />

                <Partner
                    description="Additionally, the Scheme will provide technical support services including agronomic practices in production, and post-harvest management. It will employ the technical support of the Directorate of Crop Services (DCS) and the Animal Production Directorate (APD) to build the capacity of GNSS Technical Officers in Good Agricultural Practices and productivity enhancing technologies. GNSS will also provide initial crop production agro inputs including improved seeds, fertilizers, and agro inputs, apply block-farming and cluster approach, providing full value chain services including aggregation and market facilitation for the youth." />
                <Partner
                    description="Additionally, the scheme will support 4,000 youth to undertake poultry production, processing and marketing as a business and produce 1,500.000 broiler birds over the project life. " />

                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default NSS