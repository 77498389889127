import React from 'react'

import { Link } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Partner from '../Partner';
import PartnerValueChain from './PartnerValueChain';
import Footer from '../../components/Footer';

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const MiDA = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '100,900', Soybean: '', Tomato: '', Poultry: '', Total: '100,900' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '2,560', Soybean: '', Tomato: '', Poultry: '', Total: '2,560' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Jobs', Rice: '38,269', Soybean: '', Tomato: '', Poultry: '', Total: '38,269' },
    ];
    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">MiDA</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="Ghana Cares “Obaatan Pa” Programme"
                    description="Ghana Cares “Obaatan Pa” Programme, is an economic and agricultural enclave program initiated by the Ministry of Finance. Millennium Development Authority (MiDA) serves as the Coordinator of the Programme. It has envisaged to develop, and upscale, mixed-use Economic Enclave located on an initial 4,000ha stretch of land near Asutsuare in the Shai Osudoku district in the Greater Accra Region. The Enclave is designed and developed as an integrated precinct. This Enclave concept is planned to be replicated in at least three (3) other qualifying districts across the country over a period of 2 years. At the end of second year, government (Ghana Cares Obaatanpa Programme) will hand over full operationalization and management controls to strategic well-resourced, high impact, and highly innovative privately owned Anchor farmers/management firms." />
                <Partner
                    description="The funding from Mastercard Foundation will be allocated towards initial working capital for primary production actors, innovation, training and skill development, strategy, and implementation support. This collaboration is expected to contribute significantly to reducing unemployment by creating over 38,269 jobs. It is anticipated that this intervention will impact positively on import substitution reducing it by 4% p/a from the 3rd year of the program." />
                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default MiDA