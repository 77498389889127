import axios from './index'

export function getTalk() {
    return axios.get('/talk')
}

export function updateTalk(id, {
    talkToUsTitle = '',
    talkToUsDescription = '',
    talkToUsContactButton = '',
    talkToUsLearnMoreButton = '',
} = {}) {
    return axios.put(`/updateTalkToUs/${id}`, {
        talkToUsTitle: talkToUsTitle,
        talkToUsDescription: talkToUsDescription,
        talkToUsContactButton: talkToUsContactButton,
        talkToUsLearnMoreButton: talkToUsLearnMoreButton
    })
}