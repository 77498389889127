import React from 'react'
import { Link } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Partner from '../Partner';
import PartnerValueChain from './PartnerValueChain';
import Footer from '../../components/Footer';

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const Jobberman = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Jobs', Rice: '3,200', Soybean: '2,200', Tomato: '1,500', Poultry: '1,100', Total: '8000' },
    ];
    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Jobberman</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="Jobberman Ghana"
                    description="Jobberman Ghana was established in Ghana in 2012 with the aim of digitizing Human Resources and Transforming Productivity in Ghana with the mission of developing and connecting great African talent to work opportunities. Jobberman employs a bespoke mix of human and automated solutions to match employers with the best candidates, so they can hire the right fit faster. " />
                <Partner
                    description="Jobberman will leverage on its existing initiatives such as Job Placement programme HR 360°- Job Matching, Employment Support and Outsourcing." />
                <Partner
                    description="This activity will link participants with employers in the agriculture sector through its recruitment platform, job promotion on social media and other offline methods. This is designed to attract, upskill, and create dignified and fulfilling career opportunities for 8,000 youth, over a 4-year duration, especially women to improve their wellbeing, resilience, and participation in Ghana’s formal agribusiness and adjacent sectors. It will also offer employability training for the agriculture sector by dispensing agro employability-focused education through no-tech, low-tech and high-tech platforms across the regions of Ghana to allow for inclusion and wide adoption. Social inclusion would be factored into how the training is deployed." />
                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Jobberman