import logo_light from '../assets/images/logo-light.png';
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import logo_icon_40 from '../assets/images/logo-icon-40.png';
import logo_icon_40_white from '../assets/images/logo-icon-40-white.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import image from '../assets/images/team/05.jpg';
import happy_logo from '../assets/images/Happy_logo.png';
import happy_logo_dark from '../assets/images/happy_logo_dark.png';
import ari_logo from '../assets/images/logo-agri.jpg';
import agri_impact from '../assets/images/agri_Impact.png'
import master_card from '../assets/images/master card_logo.png'

export default function Navbar(props) {
    const { navClass, topnavClass, isContainerFluid } = props;
    const [isOpen, setMenu] = useState(true);

    window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        activateMenu();
    }, []);

    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 0 ||
            document.documentElement.scrollTop >= 0
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }
    return (
        <div>
            <nav id="topnav" style={{backgroundColor: '#fff', color: '#000'}} >
                <div className="md:col-span-3 top-nav-img dark:bg-gray-800" style={{ backgroundColor: '#fff', height: '7.7rem', paddingTop: '1rem' }}>
                    <Link to="/" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '0.5rem', paddingLeft: '3rem', paddingRight: '5rem' }}>
                        <img src={master_card} alt="" style={{ width: '150px', height: '90px' }} />
                        <img src={agri_impact} alt="" style={{ width: '150px', height: '90px' }} />
                    </Link>
                </div>
                <div className={`${isContainerFluid === true ? 'container-fluid md:px-8 px-3' : 'container-md px-4'}`}>
                    <div className="menu-extras">
                        <div className="menu-item">
                            <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* <!-- End Mobile Toggle --> */}

                    {/* <!--Login button Start--> */}
                    {/* <ul className="buy-button list-none mb-0">
                    <li className="inline-block mb-0">
                        <div className="relative top-[3px]">
                            <i className="uil uil-search text-lg absolute top-[3px] end-3"></i>
                            <input type="text" className="form-input h-9 pe-10 rounded-3xl sm:w-44 w-36 border-gray-100 dark:border-slate-800 bg-white dark:bg-slate-900" name="s" id="searchItem" placeholder="Search..." />
                        </div>
                    </li>
                    <li className="inline-block ps-1 mb-0">
                        <Link to="/" className="btn btn-icon rounded-full bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"><img src={image} className="rounded-full" alt="" /></Link>
                    </li>
                </ul> */}
                    <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`} style={{ paddingRight: '5rem', color: '#fff',}}>
                        <ul className={`navigation-menu ${navClass}`}>
                            <li><Link to="/" className="sub-menu-item">Home</Link></li>
                            <li><Link to="/aboutus" className="sub-menu-item" >About Us</Link></li>
                            <li><Link to="/job-categories" className="sub-menu-item">Value Chain</Link></li>
                            <li><Link to="https://hamis.agriimpactgroup.com/dhis-web-commons/security/login.action" target="_blank" className="sub-menu-item">HAMIS</Link></li>
                            <li><Link to="https://forms.office.com/r/T6X5ryXFk9" target="_blank" className="sub-menu-item">Register</Link></li>
                            <li><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>


    )
}
