import axios from './index'

export function getFooter() {
    return axios.get('/footer')
}

export function updateFooterData(id, {
    footerHome = '',
    footerTermsAndConditions = '',
    footerAbout = '',
    footerContactUs = '',
    report = '',
    footerCopyRight = '',
    footerFacebookLink = '',
    footerTwitterLink = '',
    footerInstagramLink = '',
    footerLinkedInLink = '',
    footerImageUrl = ''
} = {}) {
    return axios.put(`/updateFooter/${id}`, {
        footerHome: footerHome,
        footerTermsAndConditions: footerTermsAndConditions,
        footerAbout: footerAbout,
        footerContactUs: footerContactUs,
        report: report,
        footerCopyRight: footerCopyRight,
        footerFacebookLink: footerFacebookLink,
        footerTwitterLink: footerTwitterLink,
        footerInstagramLink: footerInstagramLink,
        footerLinkedInLink: footerLinkedInLink,
        footerImageUrl: footerImageUrl
    })
}