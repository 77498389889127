import React from 'react'
import Navbar from '../../components/Navbar'
import Partner from '../Partner'
import { Link } from "react-router-dom";
import PartnerValueChain from './PartnerValueChain';
import Footer from '../../components/Footer';

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const GhanaEnterprise = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '', Soybean: '', Tomato: '', Poultry: '478.8', Total: '478.8' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '', Soybean: '', Tomato: '', Poultry: '240,000', Total: '240,000' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Jobs', Rice: '4,225', Soybean: '4,350', Tomato: '2,550', Poultry: '4,050', Total: '15,175' },
    ];
    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">GEA</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="Ghana Enterprises Agency (GEA)"
                    description="The Ghana Enterprises Agency (GEA), formerly the National Board for Small Scale Industries (NBSSI) is the apex body dedicated to promoting, coordinating, implementing, and developing activities in the Micro, Small, and Medium Enterprises (MSMEs) sector in Ghana. GEA has been in existence for over 41 years working to stimulate the growth of Ghanaian MSMEs to produce world-class products and services that can compete locally and internationally with a supportive enabling environment and intervention of technology transfer, entrepreneurial culture, skills development, access to finance and markets. " />
                <Partner
                    description="GEA under the project will mobilize and support 15,000 informal youth to build enterprises in various post-production activities of the rice, soybean, tomato, and poultry value chains including aggregation, milling, parboiling, packaging, product development and marketing. It also intends to produce 240,000 krouiler birds leading to the realization of a 20%  increase in the national output of Poultry." />

                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default GhanaEnterprise