import axios from './index'

export function getJobFigures() {
    return axios.get('/jobFigures')
}

export function updateJobFigures(id, figures = []) {
    return axios.put(`/updateJobFigures/${id}`, figures.map(figure => ({
        type: figure.type || '',
        value: figure.value || '',
    })));
}

export function getJobOpportunity() {
    return axios.get('/jobs')
}

export function updateJobOpportunity(id, {
    jobTitle = '',
    jobDescription = '',
    jobContactButton = '',
    jobList = [],
    jobImageUrl = []
} = {}) {
    return axios.put(`/updateJobs/${id}`, {
        jobTitle: jobTitle,
        jobDescription: jobDescription,
        jobContactButton: jobContactButton,
        jobList: jobList.map(job => ({
            jobTitle: job.jobTitle || ''
        })),
        jobImageUrl: jobImageUrl.map(image => ({
            jobImageUrls: image.jobImageUrls || ''
        }))
    });
}