//  index-five     //
import React from 'react'
import { Link } from 'react-router-dom';
import soybean from '../assets/images/hero/soybean.png';
import rice from '../assets/images/hero/rice11.png';
import tomato from '../assets/images/hero/tomato.png';
import poultry from '../assets/images/hero/poulltry-1.png';

export default function PopularCategories() {
    const Categories = [
        {
            image: rice,
            title: 'Rice',
            total: 'Total Jobs: 161,443'
        },
        {
            image: soybean,
            title: 'Soybean',
            total: 'Total Jobs: 117910'
        },
        {
            image: tomato,
            title: 'Tomato',
            total: 'Total Jobs: 29,645'
        },
        {
            image: poultry,
            title: 'Poultry',
            total: 'Total Jobs: 20,465'
        }
    ];

    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">HAPPY Footprints</h3>

                <ul className="text-left text-slate-300 max-w-lg mx-auto mt-4">
                    <li style={{ marginLeft: '0.5rem' }}>i.	Youth Entrepreneurship in Seed Systems Programme (YESSPRO)</li>
                    <li>ii. Disability Inclusive Agriculture for Prosperity (DIAP)</li>
                    <li>iii. Diversified Women Agriculture Occupations for Wealth (Di-WaOW) </li>
                    <li>iv. Small Millers Program Youth in Technology Service Delivery</li>
                    <li>v. Tomato Processing Incubation Centre (ToPIC)</li>
                    <li>vi. Drone technology</li>
                    <li>vii. E – HAPPY </li>
                </ul>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {Categories.map((item, index) => (
                    <div className="group px-3 py-10 rounded-full shadow dark:shadow-gray-700 hover:shadow-emerald-700/10 dark:hover:shadow-emerald-600/10 text-center bg-white dark:bg-slate-900 hover:bg-emerald-600/5 dark:hover:bg-emerald-600/5 transition duration-500" key={index} >
                        <div className="w-20 h-20 bg-emerald-600/5 group-hover:bg-emerald-600 text-emerald-600 group-hover:text-white rounded-full text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-700 transition duration-500 mx-auto">
                            <img src={item.image} className="h-15 w-15" alt="" />
                        </div>

                        <div className="content mt-6">
                            <Link to="#" className="title text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                            <p className="text-slate-400 mt-3">{item.total}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
