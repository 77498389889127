import React, { useState } from 'react'
import ab01 from '../assets/images/about/ab01.jpg';
import rice from '../assets/images/about/rice.jpg';
import chickent from '../assets/images/about/chicken.jpg'
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { Link } from "react-router-dom";

const customStyle = {
    color: '#5a6674',
}

export default function MillionsJob() {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} /> */}
            {/* <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-5 md:col-span-6">
                    <div className="relative">
                        <div className="relative">
                            <img src={rice} className="lg:w-[400px] w-[280px] rounded-md shadow dark:shadow-gray-700" alt="" />
                            <div className="absolute top-0 translate-y-2/4 end-0 text-center">
                                <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="yba7hPeTSjk" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-emerald-600 dark:text-white">
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="absolute md:-end-5 end-0 -bottom-16">
                            <img src={chickent} className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-md shadow dark:shadow-gray-700" alt="" />
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                    <div className="lg:ms-5">
                        <h3 className="mb-6 md:text-[26px] text-2xl md:leading-normal leading-normal font-semibold">We are a trusted partner in farming.</h3>

                        <p className="max-w-xl" style={customStyle}>We unlock value for customers, enable farming communities to prosper sustainably and strive for a food-secure future.</p>

                        <ul className="list-none mt-4" style={customStyle}>
                            <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2"></i>Lorem ipsum dolor amet lorem ipsum dolor amet</li>
                            <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2"></i>Lorem ipsum dolor amet lorem ipsum dolor amet</li>
                            <li className="mb-1 flex"><i className="uil uil-check-circle text-emerald-600 text-xl me-2"></i>Lorem ipsum dolor amet lorem ipsum dolor amet</li>
                        </ul>

                        <div className="mt-6">
                            <Link to="/contact" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white mt-2 rounded-md"><i className="uil uil-envelope"></i> Contact us</Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
