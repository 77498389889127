import React from 'react'

const Partner = ({ title, description }) => {
    return (
        <div>
            <div className="grid grid-cols-1 pb-2 text-center" >
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">
                    {title}
                </h3>
                <p className=" text-slate-300 max-w-lg mx-auto" style={{ width: '70%', textAlign: 'justify' }}>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default Partner