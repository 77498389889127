import React from 'react'
import { Link } from 'react-router-dom'
import rice from '../assets/images/hero/rice11.png';
import soybean from '../assets/images/hero/soybean.png';
import tomato from '../assets/images/hero/tomato.png';
import poultry from '../assets/images/hero/poulltry-1.png';

export default function JobCategoriesComp() {
    const JobCategoriesComp = [
        {
            image: rice,
            title: 'Rice',
            total: 'Total Jobs: 161,443'
        },
        {
            image: soybean,
            title: 'Soybean',
            total: 'Total Jobs: 117910'
        },
        {
            image: tomato,
            title: 'Tomato',
            total: 'Total Jobs: 29,645'
        },
        {
            image: poultry,
            title: 'Poultry',
            total: 'Total Jobs: 20,465'
        },

    ]

    return (
        <>

            <div className="container">
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">
                    {JobCategoriesComp.map((item, index) => (
                        <div key={index} className="group px-3 py-10 rounded-md shadow dark:shadow-gray-700 hover:shadow-emerald-600/10 dark:hover:shadow-emerald-600/10 text-center bg-white dark:bg-slate-900 hover:bg-emerald-600/5 dark:hover:bg-emerald-600/5 transition duration-500">
                            <div className="w-20 h-20 bg-emerald-600/5 group-hover:bg-emerald-600 text-emerald-600 group-hover:text-white rounded-md text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-700 transition duration-500 mx-auto">
                                <img src={item.image} alt=''></img>
                            </div>

                            <div className="content mt-6">
                                <Link to="#" className="title text-lg font-semibold hover:text-emerald-600" style={{color: '#3b82f6 !important'}}>{item.title}</Link>
                                <p className="text-slate-400 mt-3">{item.total}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}
