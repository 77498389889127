import React from 'react'
import Navbar from '../../components/Navbar'
import Partner from '../Partner'
import Footer from '../../components/Footer'
import PartnerValueChain from './PartnerValueChain'
import { Link } from "react-router-dom";

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const CatholicRelief = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '24,948', Soybean: '26,537', Tomato: '', Poultry: '1140', Total: '51,485' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '4,494', Soybean: '10,573', Tomato: '', Poultry: '', Total: '15,067' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '179,760', Soybean: '420,000',Tomato: '', Poultry: '', Total: '604,396.36'},
        { 'Value Chain': 'Total Jobs', Rice: '4,624', Soybean: '10,626', Tomato: '', Poultry: '512', Total: '15,762' },
    ];

    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">CRS</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="Catholic Relief Services (CRS)"
                    description="Catholic Relief Services - United States Conference of Catholic Bishops (CRS) has  operated in Ghana for over 65 years focusing on assisting impoverished and disadvantaged individuals globally. The firm holds a host country agreement with the Ministry of Foreign Affairs and Regional Integration and has 97 programming staff." />
                <Partner
                    description="The organization specializes in agriculture and youth-focused programming, consortium management, and has established trusted relationships with government entities at both national and local levels, civil society, and the private sector within Ghana's agriculture and youth sectors." />

                <Partner
                    description="CRS's expertise extends to rice and soybean value chains, where it supports 160,000 women and men in 15 districts, facilitating sustainable income-generating ventures." />
                <Partner
                    description="Catholic Relief Services is committed to mobilizing 19,325 youth to increase productivity of 15,656ha of rice and soybean. Additional 512 youth will be mobilized to engage in the production of 250,000 birds and other related jobs in feed distribution, farm hands, processors, and marketers. " />

                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default CatholicRelief