import React from 'react'
import { Link } from 'react-router-dom';

const customStyle = {
    color: '#5a6674',
}

export default function Feature() {
    // const featuredata = [
    //     {
    //         icon: 'phone',
    //         title: '24/7 Support',
    //         data: 'Many desktop publishing now use and a search for job'
    //     },
    //     {
    //         icon: 'atom',
    //         title: 'Agri Tech Jobs',
    //         data: 'Many desktop publishing now use and a search for job'
    //     },
    //     {
    //         icon: 'user-arrows',
    //         title: 'Quick & Easy',
    //         data: 'Many desktop publishing now use and a search for job'
    //     },
    //     {
    //         icon: 'hourglass',
    //         title: 'Save Time',
    //         data: 'Many desktop publishing now use and a search for job'
    //     },
    // ];

    return (

        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">HAPPY Initiative</h3>
                <p className=" max-w-xl mx-auto text-left" style={customStyle}>
                Over 4-year (2023-2027) integrated market-oriented catalytic agricultural value chain program, aimed at reducing Ghana’s average annual food import of $2 billion. Strategically unlocks work opportunities for youth, women-led businesses and people with diverse
abilities.
                </p>
                 <br/>
                <ul className="list-disc text-left max-w-xl mx-auto" style={customStyle}>
                    <li>Value Chains: Rice, Soybean, Tomato and Poultry</li>
                    <li>Outreach: 400,000</li>
                    <li>Youth-In-Work: 320,000 (70% women)</li>
                    <li>Increase Productivity: Rice (28%), Soybean (42%), Tomato (44%)</li>
                </ul>
            </div>

            {/* <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]" >
                {featuredata.map((item, index) => (

                    <div key={index} className="group p-6 shadow dark:shadow-gray-700 rounded-md bg-white hover:bg-emerald-600/5 dark:bg-slate-900 dark:hover:bg-emerald-600/10 text-center transition-all duration-500" >
                        <div className="w-16 h-16 flex items-center justify-center mx-auto bg-emerald-600/5 group-hover:bg-emerald-600 dark:bg-emerald-600/10 dark:group-hover:bg-emerald-600 shadow dark:shadow-gray-700 rounded-lg transition-all duration-500">
                            <i className={`uil uil-${item.icon} text-[30px] text-emerald-600 group-hover:text-white`}></i>
                        </div>

                        <div className="mt-4">
                            <Link to="#" className="text-lg font-semibold hover:text-emerald-600 transition-all duration-500" >{item.title}</Link>

                            <p className="text-slate-400 mt-3 mb-2">{item.data}</p>

                            <Link to="#" className="hover:text-emerald-600 font-medium transition-all duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                        </div>
                    </div>

                ))}
            </div> */}
        </div>
    )
}

