import nss from '../assets/images/company/nss.jpg';
import jobberman from '../assets/images/company/joberman.png';
import ministry from '../assets/images/company/ministry.jpg';
import catholic from '../assets/images/company/catholic.png';
import mida from '../assets/images/company/mida.jpg';
import ghana_enterprise from '../assets/images/company/ghana enterprise.png';
import newage from '../assets/images/company/newage.png';
import techno from '../assets/images/company/techno.png';
import React from 'react'
import { Link } from "react-router-dom";


export default function PopularJobsSeven() {
    const PopularJobsSeven = [
        {
            image: nss,
            headline: 'National Service Scheme',
            title: 'Value Chain',
            deta: 'Rice, Pulltry, Soybean, Tomato ',
            total: 'Production Target: 104,550.45',
            job: 'Jobs: 81,980',
            route: '/nss'
        },
        {
            image: catholic,
            headline: 'Catolic Relief Services',
            title: 'Value Chain',
            deta: 'Rice, Soybean, Poultry',
            total: 'Production Target: 51,485',
            job: 'Jobs: 15,762',
            route: '/catholic'
        },
        {
            image: ministry,
            headline: 'Ministry of Food & Agriculture',
            title: 'Value Chain',
            deta: 'Tomato, Rice',
            total: 'Production Target: 1,907.5',
            job: 'Jobs: 702',
            route: '/food-ministry'
        },
        {
            image: ghana_enterprise,
            headline: 'Ghana Entreprise Agency',
            title: 'Value Chain',
            deta: 'Poultry',
            total: 'Production Target: 478.8',
            job: 'Jobs: 15,175',
            route: '/gea'
        },
        {
            image: mida,
            headline: 'MIDA',
            title: 'Value Chain',
            deta: 'Rice',
            total: 'Production Target: 100,900',
            job: 'Jobs: 38,269',
            route: '/mida'
        },
        {
            image: jobberman,
            headline: 'Jobberman',
            title: 'Value Chain',
            deta: 'Across all Value Chain',
            total: 'Production Target: 0',
            job: 'Jobs: 8,000',
            route: '/jobberman'
        },
        {
            image: newage,
            headline: 'New Age',
            title: 'Value Chain',
            deta: 'Rice, Soybean',
            total: 'Production Target: 71,193.8',
            job: 'Jobs: 145,906',
            route: '/newage'
        },
        {
            image: techno,
            headline: 'Techno Serve',
            title: 'Value Chain',
            deta: 'Across all Value Chain',
            total: 'Production Target: 0',
            job: '/Jobs: 13,440',
            route: '/technoserve'
        },
    ];


    return (
        <div>


            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">HAPPY Partners</h3>

                    <p className="text-slate-300 max-w-xl mx-auto">
                    Key industry stakeholders assembled as implementing partners in a consortium providing committed lands, agro-input, support services and technical expertise
                    </p>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">

                    {PopularJobsSeven.map((item, index) => (
                        <div className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500 flex flex-col items-center justify-center" key={index} >
                            <div className="flex flex-col items-center justify-center w-full text-center">
                                <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full mb-2">
                                    <img src={item.image} className="h-11 w-11" alt="" />
                                </div>
                                {/* <Link to="#" className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500 mt-7">{item.headline}</Link> */}
                                <Link to={`${item.route}`} className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500 mt-7">{item.headline}</Link>
                            </div>

                            <div className="mt-3 flex flex-col items-center justify-center">
                                <Link to={`${item.route}`} className="text-blue-600/100 text-lg hover:text-emerald-600 font-semibold  transition-all duration-500 text-center">{item.title}</Link>
                                <p className="text-slate-200  mt-2 ">{item.deta}</p>
                                <div className="mt-1 flex flex-col items-center justify-center">
                                    <Link to="#">
                                        <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-1 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500 w-9/10 text-center">{item.type}</span>
                                    </Link>                                   
                                    <Link to="#">
                                        <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-2 text-[12px] font-medium rounded-md mt-2 me-1 transition-all duration-500 w-full text-center">{item.total}</span>
                                    </Link>
                                    <Link to="#">
                                        <span className="bg-purple-600/5 hover:bg-purple-600/20 dark:bg-purple-600/10 hover:dark:bg-purple-600/30 inline-block text-purple-600 px-3 text-[12px] font-medium rounded-md mt-2 me-1 transition-all duration-500 w-full text-center">{item.job}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

                {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <Link to="job-grid-four" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Jobs <i className="uil uil-arrow-right align-middle"></i></Link>
                    </div>
                </div> */}
            </div>

        </div>
    )
}