import axios from './index'

export function getHomeBanner() {
    return axios.get('/homeBanner')
}

export function updateBanner(id, {
    title = '',
    description = '',
    imageUrl = '',
    button = '',
} = {}) {
    return axios.put(`/homeBanner/updateHomeBanner/${id}`, {
        title: title,
        description: description,
        imageUrl: imageUrl,
        button: button
    })
}