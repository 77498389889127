// home three //

import React from 'react'
import { Link } from 'react-router-dom';

const customStyle = {
    color: '#5a6674',
}

export default function Works() {
    const home = [
        {
            title: 'Target',
            deta: 'Focus on four Value Chains: Rice, Soybean, Tomato and Poultry to increase productivity of Rice (28%), Soybean (42%), Tomato (44%) and create over 326,000 jobs',
            icon: 'uil uil-airplay'

        },
        {
            title: 'Program Intervention Areas',
            deta: 'Production expansion and productivity, Value addition, marketing and entrepreneurship and Policy Advocacy and Youth Employment ',
            icon: 'uil-hdd'
        },
        {
            title: 'The HAPPY Advantage',
            deta: 'Cluster approach which integrates market access and leverages partnerships for catalytic investment in agriculture with government buy-in, private sector involvement and youth interest to spur sustainability.',
            icon: 'uil-skip-forward'
        }
    ];

    return (

        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center" >
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Overview of HAPPY Initiative
                </h3>
                <p className="text-left text-slate-300 max-w-lg mx-auto">
                    The Harnessing Agricultural Productivity and Prosperity for Youth (HAPPY) Pogram, is a 4-year partnership between the Mastercard Foundation and Agri-Impact Limited. This transformative program, is supported by an eight member consortium Partners including NewAge Agric Solutions, National Service Scheme (NSS), Ghana Cares (MiDA), Ghana Enterprises Agency (GEA), Ministry of Food and Agriculture (MOFA), TechnoServe, Catholic Relief Services (CRS) and Jobberman. HAPPY seeks to create over 326,000 dignified and fulfilling jobs for youth (70% women) and Persons with Disability, increase food production and productivity  in rice, soya, tomato and poultry value chains by 20% and reduce import of strategic foods by 10%.
                    The program's overarching objectives include:
                </p>
                <ul className="text-left text-slate-300 max-w-lg mx-auto mt-4">
                    <li style={{marginLeft: '0.5rem'}}>i.	Increasing production and productivity within the poultry, rice, soybean, and tomato value chains</li>
                    <li>ii. Promoting value addition, fostering entrepreneurship, and facilitating market development</li>
                    <li>iii. Promoting youth employment, developing strategic policies, advancing gender, social inclusion and voice and agency for the youth</li>
                </ul>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-6 gap-[30px]">
                {home.map((item, index) => (
                    <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center" key={index}>
                        <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                            <i className={item.icon}></i>
                        </div>

                        <div className="content mt-7" >
                            <Link to="#" className="title h5 text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                            <p className="mt-3" style={customStyle}>{item.deta}</p>
                            {/* <div className="mt-5">
                    <Link to="#" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></Link>
                </div> */}
                        </div>

                    </div>

                ))}
            </div>
        </div>

    )
}