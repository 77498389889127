import React from 'react'
import Navbar from '../../components/Navbar'
import Partner from '../Partner'
import PartnerValueChain from './PartnerValueChain';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const MinistryOfFood = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '1,008', Soybean: '215', Tomato: '878', Poultry: '', Total: '1,907.5' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '200', Soybean: '', Tomato: '350', Poultry: '', Total: '550' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Jobs', Rice: '300', Soybean: '10', Tomato: '392', Poultry: '', Total: '702' },
    ];
    return (
        <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">MOFA</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="The Ministry of Food and Agriculture (MOFA)"
                    description="The Ministry of Food and Agriculture (MOFA) is the lead agency and focal point of the Government of Ghana, responsible for developing and executing policies and strategies for the agriculture sector with  core mission of promoting sustainable agriculture and thriving agribusiness through research and technology development, effective extension and support services to farmers, processors and traders for improved livelihood." />
                <Partner
                    description="Animal Production Directorate (APD) is a  Technical Directorate of the Ministry of Food and Agriculture with the mission of developing, promoting and sustaining poultry and livestock production for food security, employment creation and income generation through research, effective technical support, extension services, agro-business and industry, whilst ensuring that gender and environmental issues are adequately addressed. " />
                <Partner
                    description="Directorate of Crop Services (DCS) is a Technical Directorate of the Ministry of Food and Agriculture promoting production, facilitating processing,  distribution and marketing of industrial and export crops; quality planting materials and the efficient use and management of soil and water resources for sustainable agriculture production." />
                <Partner
                    description="Directorate of Crop Services will lead the policy interventions to be promoted by the project and be the anchor technical arm for training in agronomic practices and management. The Directorate will review and standardize all training manuals/models to be used, approve seeds to be used on the program and offer ToT for extension delivery across the project. Additionally, Directorate of Crop Services (DCS) will mentor the youth and support them to produce certified rice, soybean, and tomato seeds." />

                <Partner 
                    description="Under HAPPY, MoFA will provide 702 dignified and fulfilling jobs for youth and women, Supply 800,000 DOCs leading to the realization of a 20% increase in the national output of Poultry. Expand production to exceed 550 hectares of land for rice, tomato and soybean production."
                />
                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default MinistryOfFood