import ari_logo from '../assets/images/logo-agri.jpg';
import React from 'react'
import { Link } from "react-router-dom";


export default function Footer() {

    return (
        <footer className="relative bg-slate-900 dark:bg-slate-800">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative py-12">
                        <div className="relative w-full">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="md:col-span-3">
                                    <Link to="https://hamis.agriimpactgroup.com/dhis-web-commons/security/login.action" target='_blanc' className="flex justify-center md:justify-start focus:outline-none">
                                        <img src={ari_logo} className="" alt="" />
                                    </Link>
                                </div>
                                <div className="md:col-span-9">
                                    <ul className="list-disc footer-list ltr:md:text-right rtl:md:text-left text-center">
                                        <li className="inline-block"><Link to="/" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Home</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="https://drive.google.com/file/d/1i2FtQUIEy6Vojy7-mAWExgZuMBI4Qd--/view?usp=sharing" target='_blank' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Terms & Conditions</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/aboutus" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">About Us</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="/contact" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium me-4">Contact Us</Link></li>
                                        <li className="inline-block mt-[10px] md:mt-0"><Link to="https://happy.agriimpactgroup.com/report/web/" target='_blank' className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out font-medium">Report</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="ltr:md:text-left rtl:md:text-right text-center">
                            <p className="mb-0 text-gray-300 font-medium">Copyright © 2024 - AO Holdings | Synergy Centre. All Rights Reserved. </p>
                        </div>
                        <ul className="list-none ltr:md:text-right rtl:md:text-left text-center">
                            <li className="inline"><Link to="#" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"><i className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                            <li className="inline"><Link to="#" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"><i className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>
                            <li className="inline"><Link to="#" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"><i className="uil uil-instagram align-middle" title="instagram"></i></Link></li>
                            <li className="inline"><Link to="#" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"><i className="uil uil-twitter align-middle" title="twitter"></i></Link></li>
                            <li className="inline"><Link to="/contact" className="btn btn-icon btn-sm border-2 border-gray-800 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 text-white"><i className="uil uil-envelope align-middle" title="email"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
