import axios from './index'

export function getRegister() {
    return axios.get('/register')
}

export function updateRegister(id, {
    registerTitle = '',
    registerDescription = '',
    registerButton = '',
    backgroundImageUrl = ''
} = {}) {
    return axios.put(`/updateRegister/${id}`, {
        registerTitle: registerTitle,
        registerDescription: registerDescription,
        registerButton: registerButton,
        backgroundImageUrl: backgroundImageUrl
    })
}