import React from 'react'
import Navbar from '../../components/Navbar';
import Partner from '../Partner';
import PartnerValueChain from './PartnerValueChain';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";

const tableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '4rem',
    width: '100%'
}

const Newage = () => {
    const data = [
        { 'Value Chain': 'Productution Target(mt)', Rice: '50,831.8', Soybean: '20,362', Tomato: '', Poultry: '', Total: '71,193.8' },
        { 'Value Chain': 'Total Land required (ha)', Rice: '10,136', Soybean: '8,103', Tomato: '', Poultry: '', Total: '18,239' },
        { 'Value Chain': 'Total Seed (feed) required(kg)', Rice: '', Soybean: '', Tomato: '', Poultry: '', Total: '' },
        { 'Value Chain': 'Total Jobs', Rice: '81,012', Soybean: '64,834', Tomato: '', Poultry: '', Total: '145,906' },
    ];
  return (
    <div>
            <div style={{ marginBottom: "10rem" }}>
                <Navbar navClass='justify-end nav-light' />
            </div>
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg3.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Happy</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">NewAge</li>
                    </ul>
                </div>
            </section>
            <div style={{ marginTop: '2rem' }}>
                <Partner
                    title="Newage Agric Solutions "
                    description="Newage Agric Solutions is an agribusiness solutions provider with reliable expertise in input distribution, food production, agro-processing, out-grower and in-grower programmes – with a focus on sustainable production and consumption. It is committed to making high technology, quality agricultural inputs and services accessible to farmers and other stakeholders in Ghana’s agricultural sector. Newage prides itself in its finesse in outgrower scheme management where rural smallholder farmers are strategically targeted to empower them economically through agriculture with provision of agro-inputs on credit and ready market." />
                <Partner
                    description="Newage is contributing 18,000ha of irrigated and rainfed land for use under HAPPY to create 145,000 jobs for youth, women and persons with disability (70% women) who will be trained and supported to have a sustainable farming business. Youth will have access to lands for farming and be integrated into the network of farmers connected to Newage. Additionally, firm will support 120 youth to establish 120ha rice seed farm to supply rice seeds to farmers under the program as well as expand to other farmers outside HAPPY (YESSPRO)." />
                <div style={tableStyle}>
                    <PartnerValueChain data={data} />
                </div>
                <Footer />
            </div>
        </div>
  )
}

export default Newage